import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Children,
} from "react";
import { Stepper } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  fetchTags,
  fetchCampaign,
  fetchClientTags,
  addTagsToFile,
  confirmStandardization,
  fileCreate,
} from "./api";
import success from "../imgs/success-cropped.svg";
import profile from "../imgs/profile-cropped.svg";
import Card from "./OptionCard";
import Mapping from "./Mapping";
import Header from "./Header";
import "./Multistep.css";

export default function MultiStep() {
  const stepperRef = useRef(null);
  const warning = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [readyForUpload, setReadyForUpload] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [mappings, setMappings] = useState({});
  const [titleUpload, setTitleUpload] = useState(
    "Upload any list in csv or xlsx format"
  );
  const [selections, setSelections] = useState({});
  const [bgColors, setBgColors] = useState({});

  const client_id = localStorage.getItem("client_id");
  const [depOptions, setDepOptions] = useState({});
  const [tagsList, setTagsList] = useState(false);
  const [cold_calling, setColdCalling] = useState(() => {
    const dep = localStorage.getItem("department");
    if (dep === "CC" || dep === "CC-Texting") {
      return true;
    } else {
      return false;
    }
  });
  const [texting, setTexting] = useState(() => {
    const dep = localStorage.getItem("department");
    if (dep === "Texting" || dep === "CC-Texting") {
      return true;
    } else {
      return false;
    }
  });
  const [description, setDescription] = useState("");
  const [possibleSource, setPossibleSource] = useState("");
  const [source, setSource] = useState("Other");
  const [list_pull_date, setListPullDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  // const [skip_source, setSkipSource] = useState("Other");
  // const [skip_date, setSkipDate] = useState("");
  const [file_name, setFileName] = useState("");
  const [current_version, setCurrentVersion] = useState(1);
  const [approved, setApproved] = useState(false);
  const [getCol, setGetCol] = useState(false);
  const [campaign_id, setCampaign] = useState(() => {
    const savedCamp = sessionStorage.getItem("camapaign_id");
    return savedCamp ? JSON.parse(savedCamp) : "";
  });
  const [upload_date, setUploadDate] = useState("");
  const [file_size, setFileSize] = useState(0.0);
  const [uploaded_file, setUploadedFile] = useState(null);
  const [file_type, setFileType] = useState("CSV");
  const [original_name, setOriginalName] = useState("");
  const [activeIdx, setActiveIdx] = useState(0);
  const [progress, setProgress] = useState(0);
  const interval = useRef(null);
  const loc = location.state;
  if (loc) {
    const selectedFile = location.state.selectedFile;
    const data = location.state.data;
    console.log(selectedFile);
    console.log(data);
  }
  //total steps
  const totalIdx = 8;
  //list sources for dropDown menu
  const sources = [
    { name: "Other" },
    { name: "80/20" },
    { name: "Audantic" },
    { name: "Batch Leads" },
    { name: "Kind Skip" },
    { name: "Skip Force" },
    { name: "County Records" },
    { name: "Prop Stream" },
  ];
  //skip sources for dropDown menu
  const skipSources = [
    { name: "Kind Skip" },
    { name: "Batch Skip" },
    { name: "Prop Stream" },
    { name: "REI Sift" },
    { name: "Other" },
  ];
  //notification when upload is successful
  const notify = () => {
    toast.success(
      "Your file has been successfully processed. You can now view the details in the database",
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };
  //notification when unsuccessful
  const showErorr = () => {
    toast.error(
      <span>
        There was an issue uploading the file. Please try again later. If the
        problem continues,
        <a
          href="/contact-us"
          className="underline text-blue-600 hover:text-blue-800"
        >
          contact us
        </a>
        .
      </span>,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  //get all tags in the system
  const { data: globalTags } = useQuery("fetchTags", fetchTags, {
    onSuccess: () => {
      console.log("tags are here");
    },

    cacheTime: 1000 * 60 * 30,
  });
  //fetch campaign id
  const { data: campaigns } = useQuery(
    ["fetchCampaign", client_id],
    () => fetchCampaign(client_id),
    {
      onSuccess: () => {
        console.log("camapaings are here");
      },
      cacheTime: 1000 * 60 * 30,
    }
  );
  //fetch client tags
  const { data: clientTags } = useQuery(
    ["fetchClientTags", client_id],
    () => fetchClientTags(client_id),
    {
      onSuccess: () => {
        console.log("client tags are here");
      },
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    }
  );
  const uploadFormData = useCallback(async () => {
    const formData = new FormData();
    formData.append("client_id", client_id);
    formData.append("cold_calling", cold_calling);
    formData.append("texting", texting);
    formData.append("source", possibleSource ? possibleSource : source);
    formData.append("list_pull_date", list_pull_date);
    // formData.append("skip_source", skip_source);
    // formData.append("skip_date", skip_date);
    formData.append("previous_version", "");
    formData.append("current_version", current_version);
    formData.append("upload_date", upload_date);
    formData.append("deduplicated", false);
    formData.append("duplicates_removed", 0);
    formData.append("campaign_id", campaign_id);
    formData.append("savings", 0.0);
    formData.append("file_size", file_size);
    formData.append("skiptraced_by_res", false);
    formData.append("uploaded_file", uploaded_file);
    formData.append("file_type", file_type);
    formData.append("original_name", original_name);
    formData.append("description", description);
    Object.keys(mappings).length === 0
      ? formData.append("ready", false)
      : formData.append("ready", true);
    const fileName = `${localStorage.getItem("firstName")}${
      cold_calling ? "-CC" : ""
    }${
      texting ? "-Texting" : ""
    }-${source}-${list_pull_date}-${original_name}-v${current_version}.${file_type}`;
    setFileName(fileName);
    console.log("File name:", fileName);
    formData.append("file_name", fileName);
    let _val = progress;
    setProgress(1);
    const response = await fileCreate(formData, _val, interval, setProgress);
    console.log(response);
    const formDataFile = new FormData();
    formDataFile.append("client_id", client_id);
    formDataFile.append("file_id", response.file_id);
    formDataFile.append("tags", Object.values(selections).join(","));
    setProgress(98);
    clearInterval(interval.current);
    interval.current = null;
    const response2 = await addTagsToFile(formDataFile);
    console.log(response2);
    clearInterval(interval.current);
    interval.current = null;
    setUploadSuccessful(true);
    if (Object.keys(mappings).length > 0) {
      const formCol = new FormData();
      formCol.append("client_id", client_id);
      formCol.append("uploaded_file", uploaded_file);
      formCol.append("file_id", response.file_id);
      formCol.append("mappings", JSON.stringify(mappings));
      const response3 = await confirmStandardization(formCol);
      if (response3.success) {
        notify();
      } else {
        showErorr();
      }
    }
  }, [
    campaign_id,
    client_id,
    cold_calling,
    current_version,
    description,
    file_size,
    file_type,
    list_pull_date,
    mappings,
    original_name,
    progress,
    selections,
    source,
    texting,
    upload_date,
    uploaded_file,
  ]);
  useEffect(() => {
    if (readyForUpload) {
      uploadFormData();
    }
  }, [readyForUpload]);
  //Click on go to database or on filename
  const handleClick = () => {
    navigate("/database");
  };
  //Warning in case clicking next without entering required data
  const showWarn = () => {
    warning.current.show({
      severity: "warn",
      summary: "Warning",
      detail: " Please Enter Required Data To Proceed",
      life: 3000,
    });
  };
  // Handle clicking on next
  const handleNextClick = (current_step, state, ref) => {
    console.log(current_step);
    if (state) {
      ref.current.nextCallback();
      setActiveIdx((prev) => prev + 1);
    } else {
      if (current_step === "mapping") {
        warning.current.show({
          severity: "warn",
          summary: "Warning",
          detail: " Please Confirm The Mapping To Proceed ",
          life: 3000,
        });
      } else {
        showWarn();
      }
    }
  };
  //Handle Upload file
  const handleFileUpload = (file) => {
    // TODO: adjust file types + excel files because they cause an error
    const validTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (!validTypes.includes(file.type)) {
      warning.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "File type not supported. Please upload a CSV or Excel file.",
        life: 3000,
      });
      return;
    }
    if (file.type === "text/csv") {
      setFileType("csv");
    } else if (file.type === "application/vnd.ms-excel") {
      setFileType("xls");
    } else {
      setFileType("xlsx");
    }
    setUploadedFile(file);
    console.log(file);
    const nameTrimmed = file.name.split(".").slice(0, -1).join(".");
    setOriginalName(nameTrimmed);
    setFileSize(file.size);
    setCurrentVersion(1);
    setUploadDate(new Date().toISOString().split("T")[0]);
    setTitleUpload("Your file is uploaded successfully");
    setShowSuccessMessage(true);
  };
  //handle close button
  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  //Handle changes in source and skip source card
  const handleDepChange = (card, choice) => {
    console.log(card, choice);
    if (card === "Source") {
      console.log("Setting source here");
      if (choice === "Yes") {
        setSource(possibleSource);
      }
      if (choice !== "No" && choice !== "Yes") {
        setSource(choice);
      }
    }

    // else if (card === "SkipSource") {
    //   console.log("Setting skip source here");
    //   setSkipSource(choice);
    // }
  };
  console.log(source);
  //handle date change in list date and skip date
  const handleCalendarInput = (card, choice) => {
    if (card === "List Pull Date") {
      setListPullDate(choice);
    }
  };
  //handle multiple department selection or tags
  useEffect(() => {
    console.log(depOptions);
    console.log(selections);
    if (Object.keys(selections).length === 0) {
      setTagsList(false);
    }
    if (Object.keys(depOptions).length > 0) {
      for (const option in depOptions) {
        if (depOptions[option]) {
          console.log("Setting", option, "to true");
          switch (option) {
            case "Cold Calling":
              setColdCalling(true);

              break;
            case "Texting":
              setTexting(true);

              break;
            default:
              break;
          }
        }
      }
    }
    if (Object.keys(selections).length > 0) {
      setTagsList(true);
    }
  }, [depOptions, selections]);
  return (
    <div>
      <Header
        username={localStorage.getItem("firstName")}
        profileLink="/options"
        profileImage={profile}
        upload={true}
      />
      {showSuccessMessage && (
        <Dialog
          header="Success"
          visible={showSuccessMessage}
          onHide={closeSuccessMessage}
        >
          <div className="flex flex-col justify-center">
            <div className="alert alert-success">
              File {uploaded_file.name} uploaded successfully!
            </div>
          </div>
        </Dialog>
      )}

      <div className="flex flex-col min-h-svh gap-12">
        <Stepper ref={stepperRef} linear style={{ flexBasis: "50rem" }}>
          <StepperPanel header="Upload File">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={titleUpload}
                  upload={true}
                  image={success}
                  uploadedFile={uploaded_file}
                  onFileUpload={handleFileUpload}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-end">
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("file", uploaded_file, stepperRef);
                  setGetCol(true);
                  setApproved(false);
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Mapping ">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Mapping
                  file={uploaded_file}
                  onMapped={setApproved}
                  approved={approved}
                  fetch={getCol}
                  setFetch={setGetCol}
                  stepperRef={stepperRef}
                  mapping={setMappings}
                  suggestion={setPossibleSource}
                  client={localStorage.getItem("client_id")}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("mapping", approved, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Department">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={
                    "For which department do you intend to send this list?"
                  }
                  choices={["Cold Calling", "Texting"]}
                  onDescriptionChange={setDescription}
                  all={true}
                  selections={setDepOptions}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                disabled
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => handleNextClick("department", true, stepperRef)}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="List Source & Date">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={2}
                  dropMenu={true}
                  list={true}
                  totalIdx={totalIdx}
                  title={
                    possibleSource ? (
                      <span>
                        This looks like a list from <b>{possibleSource}</b>, is
                        that correct?
                      </span>
                    ) : (
                      ""
                    )
                  }
                  choices={possibleSource ? ["Yes", "No"] : []}
                  sources={sources}
                  onListSource={handleDepChange}
                  visible={!possibleSource}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                  date={list_pull_date}
                  pullDate={true}
                  onCalendarInput={handleCalendarInput}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() =>
                  handleNextClick("list_souce", source, stepperRef)
                }
              />
            </div>
          </StepperPanel>

          <StepperPanel header="List Tags">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                <Card
                  steps={true}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  title={
                    "Choose the tags you want to add to your list, if you have already chosen some presets they will be applied automatically"
                  }
                  tags={true}
                  clientTags={clientTags}
                  input={true}
                  vertical={true}
                  sources={globalTags}
                  selections={setSelections}
                  setBgColors={setBgColors}
                  bgColors={bgColors}
                />
              </div>
            </div>
            <Toast ref={warning} />
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
              <Button
                label="Next"
                icon="pi pi-arrow-right"
                iconPos="right"
                onClick={() => {
                  handleNextClick("tags", tagsList, stepperRef);
                  if (tagsList) {
                    setReadyForUpload(true);
                    setCampaign(campaigns[0].campaign_id);
                  }
                }}
              />
            </div>
          </StepperPanel>
          <StepperPanel header="Final Steps">
            <div className="flex items-center justify-center h-full">
              <div className=" flex-auto flex justify-content-center align-items-center font-medium">
                {" "}
                <Card
                  steps={true}
                  progress={progress}
                  activeIdx={activeIdx}
                  totalIdx={totalIdx}
                  uploadCompleted={true}
                  title={
                    uploadSuccessful
                      ? `Congratulations! Your filename is set up and ready to download. It will be safe in your account forever and used as a reference for your new lists.${
                          Object.keys(mappings).length === 0
                            ? "Our team will process your file and send you an email notification once it's ready."
                            : "We are currently breaking down your file into records and will notify you once the process is complete."
                        }`
                      : "Please wait your file is uploading"
                  }
                  fileName={uploadSuccessful ? file_name : "loading"}
                />
              </div>
            </div>
            <div className="flex justify-center items-center">
              <Button
                label="Go to Database"
                disabled={!uploadSuccessful}
                className="bg-amber-200 text-center py-2 m-4 px-5 rounded-2xl border-none text-[20px] text-black font-bold hover:bg-[#009ccf] hover:text-white"
                onClick={handleClick}
              />
            </div>
            <div className="flex pt-4 justify-content-between">
              <Button
                label="Back"
                severity="secondary"
                icon="pi pi-arrow-left"
                onClick={() => {
                  stepperRef.current.prevCallback();
                  setActiveIdx((prev) => prev - 1);
                }}
              />
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
}
