import React, { useState, useEffect, useMemo } from "react";
import { Button, Text } from "@primer/react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { ProgressBar } from "primereact/progressbar";
import { Calendar } from "primereact/calendar";
import { useQuery } from "react-query";
import { InputTextarea } from "primereact/inputtextarea";
import { Tooltip } from "primereact/tooltip";
import { AutoComplete } from "primereact/autocomplete";
import uploadImg from "../imgs/uploadimg-cropped.svg";
import "primeicons/primeicons.css";
import "./Custom.css";
import api from "./api";

export default function Card(props) {
  const navigate = useNavigate();
  const [fetchAllFiles, setFetchAllFiles] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [value, setValue] = useState("");

  const [description, setDescription] = useState("");
  const [items, setItems] = useState();
  const [selectedSource, setSelectedSource] = useState(() => {
    const savedSource = sessionStorage.getItem("selectedSource");
    return savedSource ? JSON.parse(savedSource) : null;
  });

  const savedDate = sessionStorage.getItem("selectedDate");
  const savedSource = JSON.parse(sessionStorage.getItem("selectedListSource"));
  const [visible, setVisible] = useState(() => {
    if (props.visible) {
      return true;
    }
    return savedSource ? savedSource.name !== "Yes" : false;
  });
  const [selectedListSource, setSelectedListSource] = useState(() => {
    const savedSource = sessionStorage.getItem("selectedListSource");
    return savedSource ? JSON.parse(savedSource) : null;
  });

  const [listTags, setListTags] = useState(() => {
    const savedTags = sessionStorage.getItem("listTags");
    return savedTags ? JSON.parse(savedTags) : [];
  });
  const [selectedKey, setSelectedKey] = useState(null);
  const today = new Date();
  let isTwoChoices = false;
  if (props.choices) {
    isTwoChoices = props.choices.length === 2;
  }

  //search for autocomplete tags
  const search = (event) => {
    let _filteredTags;

    if (!event.query.trim().length) {
      _filteredTags = [...props.sources];
    } else {
      _filteredTags = props.sources.filter((source) => {
        return source.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }

    setItems(_filteredTags);
  };
  //handle tag selection from autocomplete

  const handleTagSelect = (e) => {
    setValue("");
    let expiry = "";
    console.log(props.clientTags);
    props.clientTags.forEach((tag) => {
      if (tag.tag_id === e.value.tag_id) {
        expiry = tag.expiration_period;
      }
    });
    props.selections((prevSelections) => {
      const newSelections = { ...prevSelections };
      //Return an object with key tag name and value tag id withoiy duplicates
      if (Object.values(newSelections).includes(e.value.tag_id)) {
        return newSelections;
      } else {
        //Add the tag if it does not exist
        newSelections[e.value.name] = e.value.tag_id;
      }

      return newSelections;
    });
    setListTags((prevTags) => {
      // Return the existing array without adding the duplicate
      if (prevTags.some((tag) => tag.name === e.value.name)) {
        sessionStorage.setItem("listTags", JSON.stringify(prevTags));
        return prevTags;
      }
      // Add the new tag if it doesn't exist
      if (expiry === "") {
        expiry = 365;
      }
      const newTag = { name: e.value.name, expiry: expiry };
      const newTags = [...prevTags, newTag];
      console.log(newTags);

      sessionStorage.setItem("listTags", JSON.stringify(newTags));
      return newTags;
    });
    console.log(listTags);
  };
  //Remove Tags
  const removeTag = (index) => {
    props.selections((prevSelections) => {
      const newSelections = { ...prevSelections };
      delete newSelections[listTags[index].name];
      return newSelections;
    });
    const filteredTags = listTags.filter((_, i) => i !== index);

    setListTags(filteredTags);
    sessionStorage.setItem("listTags", JSON.stringify(filteredTags));
  };
  useEffect(() => {
    if (props.selectOld) {
      setFetchAllFiles(true);
    }
  }, [props.selectOld]);

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("client_id", localStorage.getItem("client_id"));
    try {
      const response = await api.post("user/all-files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("There was an error fetching the files!", error);
    }
  };
  const { data: files, isLoading: filesLoading } = useQuery(
    "fetchData",
    fetchData,
    {
      enabled: fetchAllFiles,
      cacheTime: 1000 * 60 * 10,
    }
  );
  //handle navigation to any destination
  const handleClick = () => {
    if (props.destination !== "/database") {
      sessionStorage.clear();
    }
    navigate(props.destination);
  };
  //go to database when fileName is clicked in final step
  const goToDatabase = () => {
    sessionStorage.clear();
    navigate("/database");
  };
  //file Upload card
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    sessionStorage.removeItem("selectedDate");
    sessionStorage.removeItem("selectedSource");
    sessionStorage.removeItem("selectedListSource");
    sessionStorage.removeItem("listTags");

    if (file && props.onFileUpload) {
      props.onFileUpload(file);
    }
  };
  //handle selction changes in cards with multiple selections
  const handleChange = (choice, index, choices) => {
    props.selections((prevSelections) => {
      const newSelections = { ...prevSelections };

      if (choice === "Both") {
        choices.forEach((c) => {
          newSelections[c] = true;
          props.setBgColors((prevBgColors) => {
            const newBgColors = { ...prevBgColors };
            newBgColors[c] = "#009ccf";
            return newBgColors;
          });
        });
      } else {
        if (newSelections[choice]) {
          delete newSelections[choice];
          props.setBgColors((prevBgColors) => {
            const newBgColors = { ...prevBgColors };
            newBgColors[choice] = "#8de0ff";
            return newBgColors;
          });
        } else {
          newSelections[choice] = true;
          props.setBgColors((prevBgColors) => {
            const newBgColors = { ...prevBgColors };
            newBgColors[choice] = "#009ccf";
            return newBgColors;
          });
        }
      }

      console.log(newSelections);
      return newSelections;
    });
  };
  const handleDateChange = (e) => {
    const selectedDate = e.value;
    const nextDay = new Date(selectedDate);
    nextDay.setDate(nextDay.getDate() + 1);
    // Format the date in YYYY-MM-DD format
    const formattedDate = nextDay.toISOString().split("T")[0];

    // Save the formatted date to sessionStorage
    sessionStorage.setItem("selectedDate", JSON.stringify(formattedDate));
    props.onCalendarInput("List Pull Date", formattedDate);
  };
  //handle selection changes in cards with one selection
  const handleDepChange = (choice, index, choices) => {
    choices.forEach((c) => {
      if (c !== choice) {
        props.setBgColors((prevBgColors) => {
          const newBgColors = { ...prevBgColors };
          newBgColors[c] = "#8de0ff";
          return newBgColors;
        });
      } else {
        props.setBgColors((prevBgColors) => {
          const newBgColors = { ...prevBgColors };
          newBgColors[c] = "#009ccf";
          return newBgColors;
        });
      }
    });
    setSelectedKey(index);
    if (props.list) {
      setSelectedListSource({ name: `${choice}` });
      sessionStorage.setItem(
        "selectedListSource",
        JSON.stringify({ name: `${choice}` })
      );
      if (choice === "No") {
        setVisible(true);
      } else if (choice === "Yes") {
        setVisible(false);
      }

      props.onListSource("Source", choice);
    } else {
      setSelectedSource({ name: `${choice}` });
      sessionStorage.setItem(
        "selectedSource",
        JSON.stringify({ name: `${choice}` })
      );

      props.onListSource("SkipSource", choice);
    }
  };
  //handle selections in dropDown menu
  const handleSelectionChange = (e) => {
    setSelectedFile(e.target.value.file_name);
    console.log(e.target.value.file_name);
    if (e.target.value) {
      sessionStorage.clear();
      navigate("/upload-newer-version", {
        state: { selectedFile: e.target.value.file_name, data: files },
      });
    }
  };
  //dropDown Menu for cards in multistep
  const MemoDropDown = useMemo(
    () => (
      <Dropdown
        filter
        value={props.skip ? selectedSource : selectedListSource}
        onChange={(e) => {
          const selectedValue = e.value;

          if (props.list) {
            setSelectedListSource(selectedValue);
            sessionStorage.setItem(
              "selectedListSource",
              JSON.stringify(e.value)
            );
            props.onListSource("Source", selectedValue.name);
            handleDepChange(selectedValue.name, 0, props.choices);
          } else {
            setSelectedSource(selectedValue);
            sessionStorage.setItem("selectedSource", JSON.stringify(e.value));
            props.onListSource("SkipSource", selectedValue.name);
            handleDepChange(selectedValue.name, 0, props.choices);
          }
        }}
        options={props.sources}
        optionLabel="name"
        placeholder="Other"
        className="w-full md:w-17rem font-light"
      />
    ),
    [selectedSource, selectedListSource]
  );

  return (
    <div
      className={` ${
        props.className
      } flex flex-col rounded-3xl items-center justify-center p-1 ${
        props.width
          ? `w-${[props.width]}`
          : `xs:w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4`
      } min-w-[280px] bg-gray-200 shadow-sm shadow-black`}
    >
      {props.title !== "" && (
        <div className="inline-flex items-center justify-center w-full h-auto">
          <h2
            className={`text-${
              props.steps ? "[15px]" : "[19px]"
            } text-black font-light p-[20px] `}
          >
            {props.title}
          </h2>
        </div>
      )}
      {!props.steps && (
        <div className="h-full">
          <img
            src={props.image}
            alt={props.title}
            className="w-[100px] h-[100px] flex justify-center m-[70] p-[40]"
          />
        </div>
      )}
      {props.steps && props.choices && (
        <div
          className={`flex ${
            props.vertical ? "flex-col" : "flex-row"
          } justify-center items-center w-full gap-3 p-[10px]`}
        >
          {props.choices.map((choice, index) => (
            <button
              key={index}
              className={`flex-1 ${
                props.vertical ? "px-32 w-full" : "px-2 m-1"
              } text-center justify-content-center rounded-2xl border-none text-${
                props.vertical
                  ? "black font-lighter p-2"
                  : "[20px] black font-bold"
              } ${
                selectedKey === index ? "bg-[#009ccf] " : "bg-[#8de0ff]"
              } hover:bg-[#009ccf] hover:text-white  w-[100px] h-[100px] ${
                isTwoChoices ? `text-[calc(85%)]` : `text-[14px]`
              }`}
              style={{
                backgroundColor: props.bgColors[choice],
              }}
              onClick={
                props.activeIdx !== 6 && props.activeIdx !== 1
                  ? () => handleDepChange(choice, index, props.choices)
                  : () => handleChange(choice, index, props.choices)
              }
            >
              {choice}
            </button>
          ))}
        </div>
      )}
      {props.steps && props.tags && (
        <div className="w-full m-2 flex flex-col justify-center items-center">
          <div className="card w-full flex flex-col justify-content-center px-2 mx-[20px]">
            {listTags.map((tag, index) => (
              <div
                key={index}
                className=" flex flex-col object-contain bg-white text-black text-center rounded-lg px-1 py-1 text-sm mb-2 flex items-center justify-between w-full  relative "
              >
                <button
                  aria-label="Close"
                  className="absolute bg-[#8de0ff] top-[-18px] right-[-10px] mt-1 mr-1 ml-1 text-gray-500 w-[20px] h-[20px] rounded-full hover:text-gray-700"
                  onClick={() => removeTag(index)}
                >
                  <i
                    className="pi pi-times"
                    style={{ fontSize: "0.75rem" }}
                  ></i>
                </button>
                <div className="w-full flex h-full text-start break-words text-black items-center relative overflow-auto">
                  <div className="flex justify-start hyphens-auto  break-word auto-hyphens break-words overflow-wrap-break-word w-28 whitespace-normal">
                    {tag.name}
                  </div>
                  <span className="font-bold">|</span>
                  <div className="flex justify-end items-center ml-auto  space-x-2 pl-4 flex-grow break-words ">
                    <span className="font-bold text-[#D3D3D3] ml-1">
                      {" "}
                      valid for
                    </span>
                    <span className="font-bold text-[#10c7c2] ml-1">
                      {tag.expiry}
                    </span>
                    <span className="ml-1">Days</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="card w-full flex justify-content-center px-2 mx-[20px]">
            <AutoComplete
              field="name"
              value={value}
              suggestions={items}
              completeMethod={search}
              placeholder="Enter Tag Here"
              onChange={(e) => setValue(e.value)}
              onSelect={handleTagSelect}
              className="bg-[#8de0ff] justify-center rounded-2xl  text-[15px] text-center w-full h-[40px]"
              dropdown
            />
          </div>
        </div>
      )}
      {props.steps && props.choices && props.all && (
        <div className="flex flex-col  w-full gap-3 p-[10px]">
          <Button
            className=" flex-1 px-32 w-full text-center rounded-2xl border-none text-[20px] black font-bold font-lighter p-2  bg-[#10c7c2] hover:bg-[#0caba7] hover:text-white"
            style={{ fontSize: isTwoChoices ? "calc(85%)" : "" }}
            onClick={() => handleChange("Both", 2, props.choices)}
          >
            Both Departments
          </Button>
          <h2 className="text-[15px] text-black font-light text-left  px-[5px]">
            Add description here :
          </h2>

          <InputTextarea
            autoResize
            value={description}
            onChange={(e) => {
              props.onDescriptionChange(e.target.value);
              setDescription(e.target.value);
            }}
            rows={3}
            placeholder="e.g. Prioritize this list..."
            className="w-full  p-2 border rounded text-black text-[12px] font-sans"
          />
        </div>
      )}
      {props.steps && props.uploadCompleted && props.fileName && (
        <div
          className={`flex ${
            props.vertical ? "flex-col" : "flex-row"
          } justify-center items-center w-full gap-3 p-[10px]`}
          onClick={
            props.fileName === "Please wait your file is uploading"
              ? () => {}
              : goToDatabase
          }
        >
          <div className="w-full flex flex-col object-contain justify-center items-center p-4 bg-[#8de0ff] rounded-lg gap-4 hover:bg-[#009ccf] hover:cursor-pointer">
            <Text className="w-full h-full text-center break-words text-black font-light hover:text-white">
              {props.fileName !== "loading" ? (
                props.fileName
              ) : (
                <ProgressBar className="w-full" value={props.progress} />
              )}
            </Text>
          </div>
        </div>
      )}
      {props.steps && props.dropMenu && (
        <>
          <h2
            className={`text-${
              props.steps ? "[15px]" : "[19px]"
            } text-black font-light p-[20px]  `}
          >
            When did you last pull that list?
          </h2>
          <Calendar
            className="p-2 w-full text-center calendar-input"
            value={savedDate ? new Date(savedDate) : today}
            onChange={handleDateChange}
            showInput
            showWeek
            showIcon
            maxDate={today} // Limits selection to today or earlier
          />
          {visible && (
            <>
              <h2
                className={`text-${
                  props.steps ? "[15px]" : "[19px]"
                } text-black font-light p-[20px] `}
              >
                Could you clarify where this list was sourced from?
              </h2>
              <div className="w-full m-2 flex flex-row justify-center items-center gap-3">
                <div className="card w-full flex justify-content-center px-2  ">
                  {MemoDropDown}
                </div>
              </div>
            </>
          )}
        </>
      )}

      {props.steps && props.calendar && (
        <Calendar
          className="p-2"
          value={today}
          onChange={(e) => {
            const selectedDate = e.value;
            const nextDay = new Date(selectedDate);
            nextDay.setDate(nextDay.getDate() + 1);
            const formattedDate = nextDay.toISOString().split("T")[0];
            const cardType = props.pullDate ? "List Pull Date" : "Skip Date";
            props.onCalendarInput(cardType, formattedDate);
          }}
          touchUI
          showWeek
          showIcon
          maxDate={today}
        />
      )}
      {props.steps && props.upload && (
        <div className="w-full m-2 flex flex-col justify-center items-center gap-4 p-4 bg-gray-200 rounded-lg">
          {props.uploadedFile ? (
            <div className="w-full flex flex-col object-contain justify-center items-center p-4 bg-gray-100 rounded-lg gap-4 ">
              <img src={props.image} alt="Success Icon" className="w-1/4" />
              <Text className=" w-full text-center break-words text-black font-light ">
                {props.uploadedFile.name} uploaded successfully
              </Text>
              <label
                className="cursor-pointer bg-[#009ccf] text-white font-light py-2 px-4 rounded flex items-center gap-2"
                id="fileInput"
                data-pr-tooltip="If you choose the wrong file, no worries! You can change it."
              >
                <span>Replace</span>
                <img src={uploadImg} alt="Upload Icon" className="w-8 h-8" />
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                />
                <Tooltip target="#fileInput" position="bottom" />
              </label>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center p-4 bg-gray-100 rounded-lg gap-4">
              <img src={uploadImg} alt="Upload Icon" className="w-1/2" />
              <label className="cursor-pointer bg-[#009ccf] text-white font-light py-2 px-4 rounded">
                Choose File
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          )}
        </div>
      )}
      <div className="w-full m-2 flex flex-row justify-center items-center">
        {!props.steps && !props.selectOld && (
          <Button
            className=" bg-white justify-center rounded-2xl border-none w-3/4 max-w-[200px] text-[20px] text-black font-light hover:bg-blue-100 hover:text-black"
            onClick={handleClick}
          >
            {props.buttonText}
          </Button>
        )}
        {!props.steps && props.selectOld && (
          <div className="card w-full flex justify-content-center px-2  ">
            <Dropdown
              value={selectedFile}
              onChange={handleSelectionChange}
              loading={filesLoading}
              options={files}
              optionLabel="file_name"
              placeholder="Select the older version"
              filter
              className="SelectVersion bg-white justify-center rounded-2xl border-none text-[20px]  text-black font-light hover:bg-blue-100 hover:text-black"
            />
          </div>
        )}
        {props.steps && (
          <div className="w-full bg-gray-300 rounded-2xl m-[20px]">
            <div
              className="bg-[#009ccf] h-6 rounded-2xl"
              style={{
                width: `${((props.activeIdx + 1) / props.totalIdx) * 100}%`,
              }}
            >
              <span className="text-white text-center w-full block"></span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
